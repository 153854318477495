$(function(){
            
$(".work").click(function(){
   
    $('.work-sidebar-right').toggleClass("open");
    $('.overly').toggleClass("open");
});
$(".overly").click(function(){
    $('.work-sidebar-right').removeClass("open");
    $('.overly').removeClass("open");
});
$(".work-sidebar-right *").click(function(){
    $('.work-sidebar-right').removeClass("open");
    $('.overly').removeClass("open");
});
$("#mainSortingOption li").click(function(){
    var datavalue = $(this).attr('data-name');
    if($(".more-filte .nav-item").find('.' + datavalue)){
        $(".filtermenu").removeClass('d-md-inline');
        $(".filtermenu").addClass('d-md-none');
        $('.' + datavalue).addClass('d-md-inline');
    }
});

$(".clearable").each(function() {
  
    var $inp = $(this).find("input:text"),
        $cle = $(this).find(".clearable__clear");
  
    $inp.on("input", function(){
      $cle.toggle(!!this.value);
    });
    
    $cle.on("touchstart click", function(e) {
      e.preventDefault();
      $inp.val("").trigger("input");
    });
    
});


});

